<template>
  <div class="Header">
    <div class="Navigation-bar">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"

               background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
        <!-- Logo作为菜单项 -->
        <el-menu-item class="logo-item">
          <img  src="@/image/XiangYunInfo/img/logo-text.png" alt="Logo" class="logo-image"/>
        </el-menu-item>
        <el-menu-item index="1" class="left">
          <router-link to="/">关于</router-link>
        </el-menu-item>
        <el-submenu index="2">
          <template slot="title">飞行区</template>
          <el-menu-item index="2-1">
            <router-link to="FlyOnline">联飞（飞行中心）</router-link>
          </el-menu-item>
          <el-menu-item index="2-2"><router-link to="/VA">VA系统（虚拟航司）</router-link></el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">空管区</template>
          <el-menu-item index="3-1"><router-link to="/ATCLogin">管制</router-link></el-menu-item>
          <el-menu-item index="3-2"><router-link to="/ATC/recruit">招募通知</router-link></el-menu-item>
        </el-submenu>
        <el-submenu index="6">
          <template slot="title">通知</template>
          <el-menu-item index="6-1"><router-link to="/update">更新通知</router-link></el-menu-item>
          <a href="http://www.caacnews.com.cn/"><el-menu-item index="6-2">航空新闻</el-menu-item></a>
          <a href="https://www.caac.gov.cn/index.html"><el-menu-item index="6-2">中国民用航空局</el-menu-item></a>
        </el-submenu>
        <el-menu-item index="4"><a href="http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=ZhZ0T8uV24uO2rH6rnzTqdtU54pst_7D&authKey=%2FFOfzLHJeRFD1dlObeIgNcUAD7oeC7LNpbYpO287eFqloNoibDU23UUIm%2FAhN2N4&noverify=0&group_code=870163594">加入群聊</a></el-menu-item>
        <el-menu-item index="5" disabled>商城</el-menu-item>
        <div class="container-utc">
          <p class="UTCtime">UTC Now: {{ formattedUtcTime }}</p>
        </div>
      </el-menu>
    </div>
  </div>
</template>

<script>
import '@/css/XiangYunInfo/Header.css'
import '@/css/ell.css'

export default {
  data(){
      return {
        formattedUtcTime: null,
        activeIndex: '',
        UserNumber:{
          RegisterNumber: '',
          OnlineNumber: '',
        },
      };
  },
  mounted() {
    this.updateFormattedUtcTime(); // 初始化时立即更新一次
  },
  methods:{
    updateFormattedUtcTime() {
      const now = new Date();
      // 获取年月日部分，拼接时分秒
      this.formattedUtcTime = `${now.getUTCFullYear()}-${(
          "0" +
          (now.getUTCMonth() + 1)
      ).slice(-2)}-${("0" + now.getUTCDate()).slice(-2)}--${(
          "0" + now.getUTCHours()
      ).slice(-2)}:${("0" + now.getUTCMinutes()).slice(-2)}:${(
          "0" + now.getUTCSeconds()
      ).slice(-2)}`;
    },
  },
}
</script>