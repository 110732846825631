<template>
  <div id="app">
    <HeaderTOP />
    <div class="Content">
      <div class="content-left">
        <el-row class="tac">
          <el-col :span="12">
            <el-menu :default-active="activeIndex2" class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
              <router-link to="/FlyOnline">
                <el-menu-item index="A" class="atta1"><span slot="title" class="ata2">数据中心</span></el-menu-item>
              </router-link>
              <a href="#" @click.prevent="">
                <el-menu-item index="B" class="atta1"><span slot="title" class="ata2">个人主页</span></el-menu-item>
              </a>
              <a href="#"  @click.prevent="goToMyLicence">
              <el-menu-item index="F" class="atta1"><span slot="title" class="ata2">我的执照</span></el-menu-item>
              </a>
              <a href="http://map.xiangyunfligh.cn"><el-menu-item index="C" class="atta1"><span slot="title" class="ata2">在线地图</span></el-menu-item></a>
              <router-link to="/events"><el-menu-item index="D" class="atta1"><span slot="title" class="ata2">活动列表</span></el-menu-item></router-link>
              <router-link to="/Route"><el-menu-item index="E" class="atta1"><span slot="title" class="ata2">航路查询</span></el-menu-item></router-link>
              <router-link to="/Weather"><el-menu-item index="G" class="atta1"><span slot="title" class="ata2">气象查询</span></el-menu-item></router-link>
              <router-link to="/Vip/${userId}"><el-menu-item index="H" class="atta1"><span slot="title" class="ata2">积分中心</span></el-menu-item></router-link>
              <el-button @click="qingchu">退出登录</el-button>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="content-right2">
        <div class="qqInput">
          <el-dialog title="请输入QQ号码" :visible.sync="PustQQFROM"
                     :close-on-click-modal="false"
                     :close-on-press-escape="false"
                     :show-close="false"
                      style="text-align: center">
            <span style="font-size: 17px">请输入{{UserInfo.name}}加入连飞群的QQ号码</span><br/>
            <el-input v-model="QQNumberingInput" style="width: 50%"></el-input>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="PUSTQqNmubering" style="margin-right: 40%">确 定</el-button>
            </div>
          </el-dialog>
        </div>
        <el-dialog title="签到" :visible.sync="ClockFrom" width="20%">
          <div style="font-size: 20px">
            <span>!!每次签到随机掉落100-500金钱!!</span><br>
            <span>恭喜你获得了<span  style="color: #00ac36">{{moneyNumber}}</span></span>
          </div>
          <span slot="footer" class="dialog-footer"><el-button type="primary" @click="GETClock">确定签到</el-button></span>
        </el-dialog>
        <div class="head">
          <el-button :disabled="!canClockIn" @click="Clock">{{ canClockIn ? '签到' : '已签到' }}</el-button>
          <img src="@/image/Personal/fligh.jpg">
        </div>
        <div class="contentA">
          <img class="X" v-if="violations === 1" src="@/image/Personal/Violations/Violations1.png" alt="Exception Image 1">
          <img class="X" v-if="violations === 2" src="@/image/Personal/Violations/Violations2.png" alt="Exception Image 2">
          <img class="X" v-if="violations === 3" src="@/image/Personal/Violations/Violations3.png" alt="Exception Image 3">
          <img class="X" v-if="violations === 3" src="@/image/Personal/Violations/Violations4.png" alt="Exception Image 4">
          <div class="head2">
            <div class="info-foundation">
              <div class="avatar-header">
              <el-avatar :size="120" :src="QQavatarURL"></el-avatar>
              </div>
              <div class="head2-right">
                <div class="topALLInfo"  style="display: flex">
                <div class="topinfo">
                <span id="name">{{ UserInfo.name }}</span><br/>
                <span id="numbering" :style="{ color: getColorb(UserInfo.numbering) }">{{ UserInfo.numbering }}</span>
                </div>
                <div class="topvip" style="margin-top: 14%">
                <div v-if="UserInfo.vipType==='1'" class="vip-badge"><span>VIP</span><span style="font-size: 13px"> {{UserInfo.vipGrade}}</span></div>
                <div v-if="UserInfo.vipType==='2'" class="vip-s"><span>SVIP</span><span style="font-size: 13px"> {{UserInfo.vipGrade}}</span></div>
                <div v-if="UserInfo.vipType==='3'" class="vip-year"><span>年VIP</span><span style="font-size: 13px"> {{UserInfo.vipGrade}}</span></div>
                <div v-if="UserInfo.vipType==='4'" class="Svip-year"><span>年SVIP</span><span style="font-size: 13px"> {{UserInfo.vipGrade}}</span></div>
                </div>
                </div>
                <div>
                <el-button type="text" @click="BJuserinfo = true" v-if="shouldShowDialog">编辑个人资料</el-button>
                <el-dialog title="个人资料编辑" :visible.sync="BJuserinfo">
                  <el-form :model="edit">
                    <div class="editAvatar">
                      <span id="editAvatar">上传头像暂不可用：</span><br/>
                    <input type="file" ref="fileInput" @change="previewImage"/>
                    <img v-if="previewImageUrl" :src="previewImageUrl" alt="Preview Image"/>
                    <button @click="uploadAvatar">保存并上传头像</button>
                    </div>
                      <div class="editPassword">
                        <span id="editPassword">更改密码：</span><br/>
                        <el-input class="inputpassword1" v-model="edit.Oldpassword" placeholder="请输入旧密码"></el-input>
                        <p id="editerror" v-if="edit.error.Oldpassword">{{edit.error.Oldpassword}}</p>
                        <el-input class="inputpassword1" v-model="edit.password" placeholder="请输入新密码"></el-input>
                        <p id="editerror"  v-if="edit.error.password">{{edit.error.password}}</p>
                        <el-button class="yespassword" @click="editPassword">确认修改</el-button>
                      </div>
                    <div class="inAirline">
                      <span>所属航司：</span><br/>
                      <el-input class="inAirlineinput" v-model="edit.airline" placeholder="请输入三字代码"></el-input>
                      <p id="editairline"  v-if="edit.error.airline">{{edit.error.airline}}</p>
                      <el-button class="yesairline" @click="editAirline">提交</el-button>
                    </div>
                    <div class="XYVIP">
                      <span>积分中心VIP  PLUS：</span><br/>
                      <el-button type="warning" @click="XyVip">前往开通</el-button>
                    </div>
                  </el-form>
                </el-dialog>
              </div>
                <div class="credit">
                  <span :style="{ color: getColor(UserInfo.credit) }">信用分：<span>{{ UserInfo.credit }}</span></span>
                </div>
              </div>
            </div>
            <div class="wenzi">
              <div class="University">
              <div class="N" v-if="UserInfo.University===null"><span>高校认证</span></div>
                <div class="YUniversity" v-else><img src="@/image/Personal/gaoxiao.png"/><span id="Universityzi">{{UserInfo.University }}</span></div>
            </div>
              <div class="dj">
              <div class="N" v-if="UserInfo.dj===null"><span>主播认证</span></div>
                <div class="Ydj" v-if="isBiliBili"><img :src="biliBiliIconSrc"/><span>{{UserInfo.dj.substring(3,99) }}</span></div>
                <div class="Ydj" v-if="isDouyin"><img :src="douyinIconSrc"/><span>{{UserInfo.dj.substring(3,99) }}</span></div>
              </div>
                <div class="official">
              <div class="N" v-if="UserInfo.official===null">官方认证</div>
              <div class="Yofficial" v-else><img src="@/image/Personal/v.png"/><span>{{UserInfo.official }}</span></div>
                </div>
              <div class="creditFlay">
                <div class="N" v-if="UserInfo.creditFlay===null" ><span >授信飞行员</span></div>
                <div class="NcreditFlay" v-else><img src="@/image/Personal/shouxin.png"/>{{UserInfo.creditFlay}}</div>
              </div>
              <div class="NA">
                <img v-if="UserInfo.grade === 1" src="@/image/Personal/feixingyuan.png"/>
                <img v-else src="@/image/Personal/guanzhi.png"/>
                <span v-if="UserInfo.identity" :style="{ color: GradeColor }">{{UserInfo.identity}}</span>
                <span v-if="UserInfo.grade === 1" :style="{ color: GradeColor }">飞行员</span>
              </div>
              <div class="NA"><span v-if="UserInfo.airline" style="color: #00ebff;font-size: 20px;padding-left: 13px">{{UserInfo.airline }}</span><span v-else>航空公司</span></div>
            </div>
            <span style="color: #8F98A0;margin: 4% 0 0 4%">QQ：{{UserInfo.qqNumbering}}</span>
          </div>
        </div>
        <div class="contentB">
          <hr id="hr">
          <div class="Data">
            <span id="tips-01-top">当前生涯数据</span>
            <div class="Data-Sheet">
              <div class="Data-top"><span>VA虚航生涯数据</span></div>
              <div class="grade">
                <img src="@/image/Personal/1.jpg">
                <span style="color: red">》》》》暂未开通！敬请期待~</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/css/XiangYunInfo/Header.css'
import '@/css/ell.css'
import '@/css/Bottom.css'
import '@/css/Personal/Content.css'
import axios from '@/axios-instance';
import vipView from "@/views/New/VipView.vue";
import HeaderTOP from "@/views/New/z/HeaderTOP.vue"; // 确保路径正确



export default {
  components: {HeaderTOP},
  data() {
    return {
      deadline: Date.now() + (new Date().setHours(23, 59, 59) - Date.now()),
      QQavatarURL:'',
      QQNumberingInput:'',
      PustQQFROM:false,
      canClockIn: true, // 初始状态为不可签到
      ClockFrom:false,
      moneyNumber: null, // 存储生成的随机数
      violations:'',
      userEdit:false,
      previewImageUrl: '', // 用于存储预览的图片URL
      BJuserinfo:false,
      edit: {
        error:{
          Oldpassword:'',
          password:'',
          airline:''
        },
        email: '',
        Oldpassword:'',
        password:'',
        airline:'',
      },
      shouldShowDialog: false, // 控制是否显示对话框
      formLabelWidth: '100px',
      currentUserID: null,
      userData: {},
      isOwnProfile: false,
      UserInfo: {
        identity:'',
        official: '',
        dj: '',
        University: '',
        creditFlay: '',
        credit: '',
        headimg: '',
        avatarURL: '',
        userId:'',
        id: '',
        date: '',
        name: '',
        numbering: '',
        grade: 1,
        airline: '',
        address: '',
        email: '',
      },
      biliBiliIconSrc: require('@/image/Personal/bli.png'),
      douyinIconSrc: require('@/image/Personal/dou.png'),
      activeIndex: '2-1',
      activeIndex2: 'B',
      formattedUtcTime: null,
    }
  },
  mounted() {
    this.checkClockInStatus();
    this.updateUrlWithVirtualParams();
    this.Puser();
    this.userinfo();
    this.isSign()
    this.updateFormattedUtcTime(); // 初始化时立即更新一次
    setInterval(this.updateFormattedUtcTime, 1000); // 每秒更新日期部分
  },
  computed: {
    vipView() {
      return vipView
    },
    isBiliBili() {
      // 检查 UserInfo.dj 是否不为 null 并且第一个字符为 'B'
      return this.UserInfo.dj !== null && this.UserInfo.dj.startsWith('B');
    },
    isDouyin() {
      // 检查 UserInfo.dj 是否不为 null 并且第一个字符为 '抖'
      return this.UserInfo.dj !== null && this.UserInfo.dj.startsWith('抖');
    },
    GradeColor() {
      switch (this.UserInfo.grade) {
        case 2:
        case 3:
          return '#00ff0f';
        case 5:
        case 4:
        case 6:
        case 7:
          return '#0016ff';
        case 8:
        case 9:
        case 10:
          return '#5e00ff';
        case 11:
        case 12:
          return '#e80000';
        default:
          return '#a5a5a5'; // 默认颜色
      }
    },
  },
  methods: {

    isSign(){
       this.$axios.get('/isSignUp',{params:{UserId: localStorage.getItem('user-id')}}).then(res=>{
         this.canClockIn =  res.data.data>0?false:true
         console.log(this.canClockIn)
       })
    },

    async GETClock() {
      try {
        this.ClockFrom = false
        const response = await this.$axios.get('/attendance',{params:{UserId: localStorage.getItem('user-id'),money:this.moneyNumber}})
        if (response.data.code !== 0) {
          this.$message.success('签到成功,明天记得签到哦')
        }
      } catch (error) {
        console.error('Error attendance data:', error);
        this.$message.error('签到失败：后端出现异常')
      }finally {
        this.isSign()
      }
    },
    hilarity() {
      this.$notify({
        title: "提示",
        message: "时间已到",
        duration: 0,
      });
    },
    getMoneyNumber() {
      const random = Math.random();

      // 根据概率调整随机数
      let adjustedRandom;
      if (random <= 0.09) {
        // 抽到 300 以上的概率为 40%
        adjustedRandom = Math.floor(300 + (500 - 300) * Math.random());
      } else {
        // 抽到 100 到 300 的概率为 60%
        adjustedRandom = Math.floor(100 + (300 - 100) * Math.random());
      }

      // 更新状态变量
      this.moneyNumber = adjustedRandom;
    },
    checkClockInStatus() {
      setInterval(() => {
        const now = new Date();
        const hours = now.getHours();
        if (hours === 0) {
          // 当前时间为午夜零点，可以签到
          this.canClockIn = true;
        } else {
          // 不是午夜零点，不可以签到
          this.canClockIn = false;
        }
      }, 1000 * 60); // 每分钟检查一次时间
    },
    Clock() {
      if (this.canClockIn) {
        this.getMoneyNumber()
        this.ClockFrom=true
        // 这里可以调用后端接口或者其他逻辑
      } else {
        alert('今天已经签到了！');
      }
    },
    updateUrlWithVirtualParams() {
      const token = localStorage.getItem('user-token');
      // 构造虚拟参数
      const virtualParams = new URLSearchParams({
        tk: token,
        // 其他虚拟参数...
      });

      // 获取当前页面路径
      const currentPath = this.$route.path;

      // 更新 URL
      history.replaceState({}, '', `${currentPath}?${virtualParams.toString()}`);
    },
    Puser() {
      const RuserId = this.$route.params.userId;
      const userId = localStorage.getItem('user-id');
      if (userId === RuserId) {
        this.shouldShowDialog = true;
      } else {
        this.shouldShowDialog = false;
      }
    },
    //判断有无qq号
    dontHaveQQNumbering(){
      const bendiuserId = localStorage.getItem('user-id');
      const TopuserId = this.$route.params.userId;
      //判断是否自己
      if(bendiuserId !== null && TopuserId === bendiuserId){
        if (this.UserInfo.qqNumbering !== null) {
          this.PustQQFROM = false;
          this.QQavatar();
        }else {
          this.PustQQFROM = true;
        }
      }else {
        this.PustQQFROM = false;
      }
    },
    async PUSTQqNmubering() {
      try {
        const regex = /^[1-9]\d{4,9}$/;
        if (!regex.test(this.QQNumberingInput)) {
          this.$message.error('请输入有效的QQ号');
          return;
        }
        const response = await axios.post('/write/QQNumbering', {
          numbering:this.UserInfo.numbering,
          qqNumbering: this.QQNumberingInput
        });
        if (response.data.code === 1) {
          this.$message.success("QQ绑定成功");
          this.PustQQFROM = false;
          this.$router.go(0);
        } else {
          this.$message.error("操作失败，请检查输入是否正确或联系管理员");
        }
      } catch (error) {
        // 处理网络请求错误或其他类型的错误
        if (error.response) {
          // 请求已发出，但服务器响应的状态码不在2xx范围内
          this.$message.error("服务器错误：" + error.response.statusText+"500");
        } else if (error.request) {
          // 已经创建了请求，但未收到响应
          this.$message.error("请求超时，请检查网络连接");
        } else {
          // 发生了其他错误（如设置请求时出错）
          this.$message.error("发生错误：" + error.message);
        }
      }
    },
    QQavatar(){
      if (this.UserInfo.qqNumbering!==null&&this.UserInfo.qqNumbering!=='')
      return `https://q.qlogo.cn/g?b=qq&nk=${this.UserInfo.qqNumbering}&s=640`;
    },
    goToMap(){
      this.$router.push({path: `/Map`});
    },
    qingchu() {
      localStorage.removeItem('user-token');
      localStorage.removeItem('user-id');
      // 可以选择重定向到登录页面或其他操作
      this.$router.push('/login');
    },
    goToMyLicence() {
      const token = localStorage.getItem('user-token');
      const userId = localStorage.getItem('user-id');
      if (token) {
        this.$router.push({path: `/MyLicence/user/${userId}`});
      } else {
        this.$router.push('/login');
      }
    },
    XyVip(){
      const userId = localStorage.getItem('user-id');
      this.$router.push({path: `/vip/${userId}`});
    },
    async editPassword() {
      if (!this.edit.Oldpassword.trim()) {
        this.edit.error.Oldpassword = '密码不能为空!';
        return;
      }

      try {
        const response = await axios.post("/edit/query/password", {
          password: this.edit.Oldpassword,
          userId: this.UserInfo.userId
        });

        if (response.data.code === 1) {
          // 密码验证成功
          this.edit.error.Oldpassword
          const updateResponse = await axios.post("/edit/password", {
            password: this.edit.password, // 注意这里应该是新密码
            userId: this.UserInfo.userId
          });

          if (updateResponse.data.code === 1) {
            this.edit.error.Oldpassword
            this.$message.success("密码修改成功");
          } else {
            this.$message.error("密码修改失败");
          }
        } else {
          this.edit.error.Oldpassword = '密码错误';
        }
      } catch (error) {
        this.edit.error.Oldpassword = '请求错误';
        console.error('请求错误', error);
      }
    },
    async editAirline() {
      if (!this.edit.airline.trim()) {
        this.edit.error.airline = '航司代码不能为空!';
        return;
      } else {
        const response = await axios.post("/edit/airline", {
          userId: this.UserInfo.userId,
          airline: this.edit.airline
        })
        if (response.data.code === 1) {
          this.$message.success("航司修改成功")
        } else {
          this.$message.error("航司修改失败")
        }
      }
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        // 使用FileReader API预览图片
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImageUrl = e.target.result; // 将预览图片的URL赋值给previewImageUrl
        };
        reader.readAsDataURL(file);
        this.selectedFile = file; // 保存选择的文件以备上传
      } else {
        this.$message.error('没有选择图片');
      }
    },
    async uploadAvatar()
    {
      if (!this.selectedFile) {
        this.$message.error('没有图片需要上传');
        return;
      }
      try {
        const formData = new FormData();
        formData.append('image', this.selectedFile);
        const userId = localStorage.getItem('user-id');
        formData.append('userId', userId); // 使用从localStorage获取的userId值
        await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.$message.success("上传成功，请刷新")
      } catch (error) {
        console.error('图片上传失败:', error);
        this.$message.error('图片上传失败，请稍后再试！');
      }
    }
,
    getColor(credit) {
      if (credit < 70) {
        return 'red';
      } else if (credit < 80) {
        return 'orange';
      } else if (credit >= 90) {
        return 'green';
      }
      // Default color if none of the conditions are met
      return 'black';
    },
    getColorb(numbering){
      if (numbering === '未获得连线资格') {
        return 'red';
      } else  {
        return '#6e6e6e'
      }
    },
    async userinfo() {
      try {
        const userId = this.$route.params.userId;
        const response = await axios.post(`/user/${userId}`);
        const data = response.data;
        this.currentUserID = data.userId
        this.UserInfo = data; // 假设this.username是Vue组件中的一个数据属性
        this.UserInfo.University=data.university;
        this.violations=data.violations
        this.dontHaveQQNumbering()
        this.QQavatarURL = this.QQavatar();

      } catch (error) {
        console.error('Failed to fetch user information', error);
      }
    },
    updateFormattedUtcTime() {
      const now = new Date();
      // 获取年月日部分，拼接时分秒
      this.formattedUtcTime = `${now.getUTCFullYear()}-${('0' + (now.getUTCMonth() + 1)).slice(-2)}-${('0' + now.getUTCDate()).slice(-2)}--${('0' + now.getUTCHours()).slice(-2)}:${('0' + now.getUTCMinutes()).slice(-2)}:${('0' + now.getUTCSeconds()).slice(-2)}`;
    }
  }
}
</script>
<style scoped>
.editAvatar{
  margin-left: 10px;
  width: 100%;
}
#editAvatar{
  color: #171D25;
  font-size: 15px;
}
.editAvatar input{
  width: 50%;
  height: 30px;
  margin-top: 5px;
}
.editPassword{
  margin-top: 20px;
  margin-left: 10px;
  width: 40%;
}
#editPassword{
  color: #171D25;
  font-size: 15px;
}
.editPassword input{
  margin-top: 5px;
  margin-bottom: 10px;
  width: 70%;
  height: 35px;
}
.yespassword{
  width: 40%;
}
#editerror{
  color: red;
  font-size: 13px;
}
.inAirline{
  margin-left: 10px;
  margin-top: 20px;
  width: 20%;
  height: 30%;
}
.inAirline span{
  font-size: 15px;
  color: #171D25;
}
.inAirlineinput{
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}
.yesairline{
  width: 50%;
}
.XYVIP{
  margin-left: 10px;
  margin-top: 20px;
}
.vip-badge{
  width: 200%;
  height: 25px;
  margin-left: 25%;
  text-align: center;
  font-size: 21px;
  background-image: linear-gradient(to right, #ff9a28, #afff38);
  color: #c57200;
  border-radius: 50px; /* 圆角 */
}
.vip-s{
  width: 200%;
  height: 25px;
  text-align: center;
  margin-left: 5%;
  font-size: 19px;
  background-image: linear-gradient(to right, #ff7e2e, #f5ff00);
  color: #832e00;
  border-radius: 50px; /* 圆角 */
}
.vip-year{
  width: 200%;
  height: 25px;
  margin-left: 5%;
  text-align: center;
  font-size: 19px;
  background-image: linear-gradient(to right, #ff7676, #ffe262);
  color: #6a4e00;
  border-radius: 50px; /* 圆角 */
}
.Svip-year{
  width: 170%;
  height: 25px;
  margin-left: 5%;
  text-align: center;
  font-size: 17px;
  background-image: linear-gradient(to right, #6700ff, #ff00fd);
  color: #540071;
  border-radius: 50px; /* 圆角 */
}
.head .el-button {
    position: absolute; /* 设置为绝对定位 */
    top: 70px; /* 距离顶部的距离 */
    left: 190px; /* 距离左边的距离 */
    z-index: 2; /* 设置较高的层级，确保按钮在图片上方 */
}
</style>