<template>
  <div id="app">
    <HeaderTOP />
    <div class="Content">
      <div class="content-left">
        <el-row class="tac" >
          <el-col :span="12">
            <el-menu :default-active="activeIndex2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
              <router-link to="/FlyOnline"><el-menu-item index="A" class="atta1"><span slot="title" class="ata2">数据中心</span></el-menu-item></router-link>
              <a href="#" @click.prevent="goToPersonal"><el-menu-item index="B" class="atta1"><span slot="title" class="ata2">个人主页</span></el-menu-item></a>
              <a href="#" @click.prevent="goToMyLicence"><el-menu-item index="F" class="atta1"><span slot="title" class="ata2">我的执照</span></el-menu-item></a>
              <a href="http://map.xiangyunfligh.cn"><el-menu-item index="C" class="atta1"><span slot="title" class="ata2">在线地图</span></el-menu-item></a>
              <router-link to="/events"><el-menu-item index="D" class="atta1"><span slot="title" class="ata2">活动列表</span></el-menu-item></router-link>
              <router-link to="/Route"><el-menu-item index="E" class="atta1"><span slot="title" class="ata2">航路查询</span></el-menu-item></router-link>
              <router-link to="/Weather"><el-menu-item index="G" class="atta1"><span slot="title" class="ata2">气象查询</span></el-menu-item></router-link>
              <router-link to="/Vip/${userId}"><el-menu-item index="H" class="atta1"><span slot="title" class="ata2">积分中心</span></el-menu-item></router-link>
              <el-button @click="qingchu">退出登录</el-button>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="r-Content">
        <div class="content-top" style="display: flex">
          <div class="top-left" style="margin-top: 1% ;margin-left: 1%">
            <span>权限组：</span>
            <div v-if="UserInfo.vipType==='1'" class="vip-badge"><span>VIP</span><span style="font-size: 13px"> {{UserInfo.vipGrade}}</span></div>
            <div v-if="UserInfo.vipType==='2'" class="vip-s"><span>SVIP</span><span style="font-size: 13px"> {{UserInfo.vipGrade}}</span></div>
            <div v-if="UserInfo.vipType==='3'" class="vip-year"><span>年VIP</span><span style="font-size: 13px"> {{UserInfo.vipGrade}}</span></div>
            <div v-if="UserInfo.vipType==='4'" class="Svip-year"><span>年SVIP</span><span style="font-size: 13px"> {{UserInfo.vipGrade}}</span></div>
            <span>我的金钱：{{UserInfo.money}}</span><br/>
            <span>vip到期时间:{{UserInfo.vipDuration}}</span>
          </div>
          <div class="top-right">
            <el-button @click="pay = true">充值积分</el-button>
            <el-dialog title="选择充值通道" :visible.sync="pay" width="17%">
              <span>积分兑换率10000：1</span><br>
              <el-button @click="RGpayB">人工通道（1分钟到账）</el-button><br/><br/>
              <el-button @click="ZZpayB">自助充值（12小时到账）</el-button>
            </el-dialog>

            <el-dialog title="人工充值通道" :visible.sync="RGpay" width="25%">
              <div style="display: flex">
              <div>
                <span>微信联系9887</span><br/>
                <img src="@/image/PAY/WX-MA.jpg" style="width: 93%">
              </div>
              <div>
                <span>QQ联系9887</span>
                <img src="@/image/PAY/QQ-MA.jpg" style="width: 90%">
              </div>
              </div>
            </el-dialog>

            <el-dialog title="自助充值通道" :visible.sync="ZZpay" width="30%">
              <span style="font-size: 18px;color:brown">备注编号，3元起充值，以上有一点无遵守就当捐赠咯</span>
              <div style="display: flex;">
                <div>
                  <span style="color: #00ac36">微信支付</span>
                  <img src="@/image/PAY/WXpay.jpg" style="width: 100%">
                </div>
                <div style="margin-left: 4%">
                  <span style="color: blue">支付宝支付</span><br>
                  <img src="@/image/PAY/ZFBpay.jpg" style="width: 65%">
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
        <div class="message" style="margin: 1%;width: 96%" v-loading="loading">
          <el-alert title="积分充值与下单通知！！！！！必看！！！！！" type="warning" :closable="false" show-icon>
            <span>积分兑换率为10000:1,也就是1万积分等于1RMB</span><br/>
            <span>充值积分仅接受3元以上</span>
            <span>提现积分仅支持人工通道</span>
          </el-alert>
        </div>
        <div class="r-Content-2">
            <div class="vip">
              <div class="vip-0">
                <span id="shops-tips">无会员</span><br/>
                <span id="money-tips">价格:<span id="money">{{0}}</span></span>
                <div class="privilege">
                  <div class="privilege-2">
                    <hr>
                    <span>客服：群里问问题</span><br>
                    <span>成长值：1%增长速度</span><br>
                    <span>---授信特权---</span><br>
                    <span>主页授信展示</span><br>
                    <span>生日祝贺</span><br>
                    <span>生日8折商城优惠</span><br>
                    <span>不允许发布活动</span><br>
                  </div>
                </div>
                <el-button type="danger" class="buttonsCon" plain disabled>不可购买</el-button>
              </div>
              <div class="vip-07day">
                <span id="shops-tips">7天vip会员</span><br/>
                <span id="money-tips">价格:<span id="money">{{7}}<span id="money" style="font-size: 33px">0000</span></span></span>
                <div class="privilege">
                  <div class="privilege-2">
                    <hr>
                    <span>会员日：无</span><br>
                    <span>每周免费资源：5个</span><br>
                    <span>商城折扣：8.5折</span><br>
                    <span>客服：青铜客服8分钟响应</span><br>
                    <span>首页vip展示</span><br>
                    <span>成长值：10%增长速度</span><br>
                    <span>---授信特权---</span><br>
                    <span>主页授信展示</span><br>
                    <span>首页生日播报</span><br>
                    <span>生日7折商城优惠</span><br>
                    <span>允许发布娱乐活动内42小时审核</span><br>
                  </div>
                </div>
                <el-button type="success" class="buttonsCon" v-if="UserInfo.money>70000" @click="byshop(1,70000)">购买</el-button>
                <el-button type="info" class="buttonsCon" v-else plain disabled>积分不足</el-button>
              </div>
              <div class="vip-yue">
                <span id="shops-tips">月度vip会员</span><br/>
                <span id="money-tips">价格:<span id="money">{{12}}<span id="money" style="font-size: 33px">0000</span></span></span>
                <div class="privilege">
                <div class="privilege-2">
                  <hr>
                  <span>会员日：每月6号</span><br>
                  <span>每周免费资源：5个</span><br>
                  <span>商城折扣：8.5折</span><br>
                  <span>客服：青铜客服8分钟响应</span><br>
                  <span>首页vip展示</span><br>
                  <span>成长值：10%增长速度</span><br>
                  <span>---授信特权---</span><br>
                  <span>入驻VA虚拟航司</span><br>
                  <span>主页授信展示</span><br>
                  <span>首页生日播报</span><br>
                  <span>生日7折商城优惠</span><br>
                  <span>允许发布娱乐活动内42小时审核</span><br>
                </div>
                </div>
                <el-button type="success" class="buttonsCon" v-if="UserInfo.money>120000" @click="byshop(2,120000)">购买</el-button>
                <el-button type="info" class="buttonsCon" v-else plain disabled>积分不足</el-button>
              </div>
              <div class="svip-yue">
              <span id="shops-tips">月度SVIP会员</span><br/>
                <span id="money-tips">价格:<span id="money">{{38}}<span id="money" style="font-size: 33px">0000</span></span></span>
                <div class="privilege">
                  <div class="privilege-2">
                    <hr>
                    <span>会员日：每月6号</span><br>
                    <span>每周免费资源：8个</span><br>
                    <span>商城折扣：7.5折</span><br>
                    <span>客服：白银客服5分钟响应</span><br>
                    <span>首页svip展示</span><br>
                    <span>成长值：20%增长速度</span><br>
                    <span>---授信特权---</span><br>
                    <span>入驻VA虚拟航司</span><br>
                    <span>主页授信展示</span><br>
                    <span>生日播报</span><br>
                    <span>生日6折商城优惠</span><br>
                    <span>发布娱乐活动审核24小时内</span><br>
                  </div>
                </div>
                <el-button type="success" class="buttonsCon" v-if="UserInfo.money>380000" @click="byshop(3,380000)">购买</el-button>
                <el-button type="info" class="buttonsCon" v-else plain disabled>积分不足</el-button>
            </div>
              </div>
          <div class="else">
            <div class="vip-years">
              <span id="shops-tips" style="font-style: italic;font-size: 18px;">年VIP会员</span><br/>
              <span id="money-tips">价格:<span id="money">{{188}}<span id="money" style="font-size: 33px">0000</span></span></span>
              <div class="privilege">
                <div class="privilege-2">
                  <hr>
                  <span>会员日：每月6号</span><br>
                  <span>每周免费资源：13个</span><br>
                  <span>商城折扣：5折</span><br>
                  <span>客服：砖石客服3分钟响应</span><br>
                  <span>首页年vip展示</span><br>
                  <span>成长值：35%增长速度</span><br>
                  <span>---授信特权---</span><br>
                  <span>入驻VA虚拟航司</span><br>
                  <span>主页授信展示</span><br>
                  <span>首页生日播报</span><br>
                  <span>生日3折商城优惠</span><br>
                  <span>允许发布活动娱乐活动12小时审核</span><br>
                </div>
              </div>
              <el-button type="success" class="buttonsCon" v-if="UserInfo.money>1880000" @click="byshop(4,1880000)">购买</el-button>
              <el-button type="info" class="buttonsCon" v-else plain disabled>积分不足</el-button>
            </div>
            <div class="svip-yaers" >
              <span id="shops-tips"  style="font-style: italic;font-size: 18px;">年超级会员</span><br/>
              <span id="money-tips">价格:<span id="money">{{388}}<span id="money" style="font-size: 33px">0000</span></span></span>
              <div class="privilege">
                <div class="privilege-2">
                  <hr>
                  <span>会员日：每月6号</span><br>
                  <span>每周免费资源：全部</span><br>
                  <span>商城折扣：5折</span><br>
                  <span>客服：王牌客服1分钟响应</span><br>
                  <span>首页年Svip展示</span><br>
                  <span>成长值：45%增长速度</span><br>
                  <span>---授信特权---</span><br>
                  <span>入驻VA虚拟航司</span><br>
                  <span>主页授信展示</span><br>
                  <span>首页生日播报</span><br>
                  <span>生日1.9折商城优惠</span><br>
                  <span>B级活动42小时审核C级8小时审核</span><br>
                </div>
              </div>
              <el-button type="success" class="buttonsCon" v-if="UserInfo.money>3880000" @click="byshop(5,3880000)">购买</el-button>
              <el-button type="info" class="buttonsCon" v-else plain disabled>积分不足</el-button>
            </div>
            <div class="GETname">
                <span id="shops-tips">改名卡</span><br/>
              <span id="money-tips">价格:<span id="money">{{10}}<span id="money" style="font-size: 33px">0000</span></span></span>
              <div class="privilege">
                <div class="privilege-2">
                  <hr>
                  <span>购买后请立即使用</span><br>
                </div>
              </div>
              <el-button type="success" class="buttonsCon" v-if="UserInfo.money>100000" @click="byshop(6,100000)">购买</el-button>
              <el-button type="info" class="buttonsCon" v-else plain disabled>积分不足</el-button>
            </div>
            <div class="GETnumbering">
              <span id="shops-tips">靓号UID</span><br/>
              <span id="money-tips">价格:<span id="money">{{10}}<span id="money" style="font-size: 33px">0000</span></span></span>
              <div class="privilege">
                <div class="privilege-2">
                  <hr>
                  <span>购买后请立即使用</span><br>
                </div>
              </div>
              <el-button type="success" class="buttonsCon" v-if="UserInfo.money>100000" @click="byshop(7,100000)">购买</el-button>
              <el-button type="info" class="buttonsCon" v-else plain disabled>积分不足</el-button>
            </div>
          </div>
          <div class="else">
            <div class="GETnumbering">
              <span id="shops-tips">入驻航司（VA）</span><br/>
              <span id="money-tips">价格:<span id="money">{{10}}<span id="money" style="font-size: 33px">0000</span></span></span>
              <div class="privilege">
                <div class="privilege-2">
                  <hr>
                  <span>购买后请立即使用</span><br>
                  <span>赠送50000航司奖励金钱</span><br>
                  <span>审核时间12小时</span><br>
                </div>
              </div>
              <el-button type="success" class="buttonsCon" v-if="UserInfo.money>100000" @click="byshop(8,100000)">购买</el-button>
              <el-button type="info" class="buttonsCon" v-else plain disabled>积分不足</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom" style="height: 300px;margin-top: 5%">

    </div>
  </div>
</template>

<script>


import axios from "@/axios-instance";
import HeaderTOP from "@/views/New/z/HeaderTOP.vue";

export default {
  components: {HeaderTOP},
  data(){
    return{
      pay:false,
      ZZpay:false,
      RGpay:false,
      loading:false,
      formattedUtcTime: null,
      activeIndex: '2-1',
      activeIndex2: 'H',
      UserInfo:{},
      QQavatarURL:''
    }
  },
  mounted() {
    this.userinfo()
    this.updateFormattedUtcTime(); // 初始化时立即更新一次
    setInterval(this.updateFormattedUtcTime, 1000); // 每秒更新日期部分
  },
  methods:{
    async byshop(ItemId, sum) {
      try {
        this.loading = true;
        const response = await axios.post('/buy/goods',
            {
              userId: this.UserInfo.userId,//用id识别谁买的
              itemId: ItemId,//用type区别买的商品类型
              sum: sum,//需要扣除的金额
            })
        if (response.data.code === 1) {
          this.$message.success('购买成功扣除金钱:' + sum)
          setTimeout(() => {
            location.reload(); // 刷新当前页面
            this.loading = false;
          }, 2000); // 3000毫秒等于3秒
        } else {
          this.$message.error('购买失败请稍后再试或联系管理员')
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.$message.error('后端错误本次金额不会扣除，'+error)
      }
    },
    RGpayB(){
      this.pay=false;
      this.RGpay=true;
    },
    ZZpayB(){
      this.pay=false;
      this.ZZpay=true;
    },
    qingchu() {
      localStorage.removeItem('user-token');
      localStorage.removeItem('user-id');
      // 可以选择重定向到登录页面或其他操作
      this.$router.push('/login');
    },
    goToMap(){
      this.$router.push({path: `/Map`});
    },
    goToMyLicence() {
      const token = localStorage.getItem('user-token');

      if (token) {
        const userId = localStorage.getItem('user-id');
        this.$router.push({path: `/MyLicence/user/${userId}`});
      } else {
        this.$router.push('/login');
      }
    },
    goToPersonal() {
      const token = localStorage.getItem('user-token');

      if (token) {
        const userId = localStorage.getItem('user-id');
        this.$router.push({path: `/Personal/user/${userId}`});
      } else {
        this.$router.push('/login');
      }
    },
    QQavatar(){
      if (this.UserInfo.qqNumbering!==null&&this.UserInfo.qqNumbering!=='')
        return `https://q.qlogo.cn/g?b=qq&nk=${this.UserInfo.qqNumbering}&s=640`;
    },
    async userinfo() {
      try {
        const userId = localStorage.getItem('user-id');
        const response = await axios.post(`/user/${userId}`);
        const data = response.data;
        this.currentUserID = data.userId
        this.UserInfo = data; // 假设this.username是Vue组件中的一个数据属性
        this.QQavatarURL = this.QQavatar();
      } catch (error) {
        console.error('Failed to fetch user information', error);
      }
    },
    handleSelect(item) {
      console.log('Selected item:', item);
    },
    handleOpen(item) {
      console.log('Opened item:', item);
    },
    handleClose(item) {
      console.log('Closed item:', item);
    },
    updateFormattedUtcTime() {
      const now = new Date();
      // 获取年月日部分，拼接时分秒
      this.formattedUtcTime = `${now.getUTCFullYear()}-${('0' + (now.getUTCMonth() + 1)).slice(-2)}-${('0' + now.getUTCDate()).slice(-2)}--${('0' + now.getUTCHours()).slice(-2)}:${('0' + now.getUTCMinutes()).slice(-2)}:${('0' + now.getUTCSeconds()).slice(-2)}`;
    },
  }
}
</script>

<style scoped>
.top-right{

}
.Content{
  width: 100%;
}
.r-Content{
  width: 100%;
}

.r-Content-2 div{
  border-radius: 30px; /* 圆角 */
  text-align: center;
}
.vip{
  display: flex;
  justify-content: center; /* 水平居中 */
  width: 100%;
}
.vip-0{
  width: 22%;
  height: 410px;
  background-image: linear-gradient(to right, #e3e3e3, #9e9e9e);
}
.vip-07day{
  width: 22%;
  height: 410px;
  margin-left: 1%;
  background-image: linear-gradient(to right, #fff693, #afff38);
}
.vip-yue{
  width: 22%;
  height: 410px;
  margin-left: 1%;
  background-image: linear-gradient(to right, #ff9a28, #afff38);
}
.svip-yue{
  width: 22%;
  height: 410px;
  margin-left: 1%;
  background-image: linear-gradient(to right, #ff7e2e, #f5ff00);
}
.vip-years{
  width: 22%;
  height: 410px;
  background-image: linear-gradient(to right, #ff7676, #ffe262);
}
.svip-yaers{
  width: 22%;
  height: 410px;
  margin-left: 1%;
  background-image: linear-gradient(to right, #6700ff, #ff00fd);
}
#money-tips{
  font-size: 18px;
}
#money{
  font-size: 42px;
  background-image: linear-gradient(to right, #ffffff, #dfdfdf);
  -webkit-background-clip: text; /* WebKit 浏览器 */
  -moz-background-clip: text; /* Firefox */
  background-clip: text; /* 标准属性 */
  color: transparent; /* 使文字本身透明 */
}
#shops-tips{
  font-size: 17px;
}


.vip-badge{
  width: 60%;
  height: 25px;
  margin-left: 5%;
  text-align: center;
  font-size: 17px;
  background-image: linear-gradient(to right, #ff9a28, #afff38);
  color: #c57200;
  border-radius: 50px; /* 圆角 */
}
.vip-s{
  width: 60%;
  height: 25px;
  margin-left: 5%;
  text-align: center;
  font-size: 17px;
  background-image: linear-gradient(to right, #ff7e2e, #f5ff00);
  color: #832e00;
  border-radius: 50px; /* 圆角 */
}
.vip-year{
  width: 60%;
  height: 25px;
  margin-left: 5%;
  text-align: center;
  font-size: 17px;
  background-image: linear-gradient(to right, #ff7676, #ffe262);
  color: #876100;
  border-radius: 50px; /* 圆角 */
}
.Svip-year{
  width: 60%;
  height: 25px;
  margin-left: 5%;
  text-align: center;
  font-size: 17px;
  background-image: linear-gradient(to right, #6700ff, #ff00fd);
  color: #540071;
  border-radius: 50px; /* 圆角 */
}


.else{
  display: flex;
  justify-content: center; /* 水平居中 */
  margin-top: 2%;
  width: 100%;
}
.GETname{
  width: 22%;
  height: 250px;
  margin-left: 1%;
  background-image: linear-gradient(to right, #5479ff, #c5ffe7);
}
.GETnumbering{
  width: 22%;
  height: 250px;
  margin-left: 1%;
  background-image: linear-gradient(to right, #ffb400, #fbff4d);
}
.privilege {
  margin-top: 1%;
  display: flex;       /* 使父元素成为Flex容器 */
  justify-content: center; /* 水平居中其子元素 */
}

.privilege-2 {
  display: inline-block; /* 让.privilege-2成为行内块元素 */
  text-align: left !important;      /* 文本内容靠左对齐 */
  width: 70%;            /* 设置宽度为50% */
}
.buttonsCon{
  margin-top: 8%;
}
</style>